import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SharelockCommonModule } from '../../modules/sharelock-common.module';
import { ISnackBarData } from './snack-bar.model';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'shrl-snack-bar',
  templateUrl: './snack-bar.component.html',
  standalone: true,
  imports: [SharelockCommonModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharelockSnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData) {}
}
