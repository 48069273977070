<span class="shrl-text-body-1">
    @if (data.label) {
        {{ data.label }}
    } @else if (data.labelCode) {
        {{ data.labelCode | translate }}
    }
</span>
@if (data.actionLabel) {
    <button
        class="ml-5"
        mat-raised-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        {{ data.actionLabel }}
    </button>
}
