import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SharelockSnackBarComponent } from '../../components/snack-bar/snack-bar.component';
import { ISnackBarData } from '../../components/snack-bar/snack-bar.model';
import { TranslateService } from '@ngx-translate/core';

// service for basic snackbars
// for a custom one just use directly MatSnackBar
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly DEFAULT_DURATION = 5000;
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  openSnackbarInfo = (
    data: ISnackBarData,
    duration: number = this.DEFAULT_DURATION
  ): MatSnackBarRef<SharelockSnackBarComponent> =>
    this.openSnackbar(data, duration, 'info-snackbar');

  openSnackbarSuccess = (
    data: ISnackBarData = {
      labelCode: 'SHARED.GENERAL.SUCCESS',
    },
    duration: number = this.DEFAULT_DURATION
  ): MatSnackBarRef<SharelockSnackBarComponent> =>
    this.openSnackbar(data, duration, 'success-snackbar');

  openSnackbarError = (
    data: ISnackBarData,
    duration: number = this.DEFAULT_DURATION
  ): MatSnackBarRef<SharelockSnackBarComponent> =>
    this.openSnackbar(data, duration, 'error-snackbar');

  private openSnackbar(
    data: ISnackBarData,
    duration: number,
    panelClass: string
  ): MatSnackBarRef<SharelockSnackBarComponent> {
    return this.snackBar.openFromComponent(SharelockSnackBarComponent, {
      data,
      panelClass,
      duration,
    });
  }
}
